'use client'

import useIsMounted from 'ismounted'
import { FC, createContext, useEffect, useState } from 'react'

export type LOGINSTATUS = 'loggedIn' | 'loggedOut' | 'loading'
export type LoginStatusContextType = LOGINSTATUS | null
export const loginStatusContext = createContext<LoginStatusContextType>(null)

export const WithLoginState: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [loginStatus, setLoginStatus] = useState<LOGINSTATUS>('loading')
  const isMounted = useIsMounted()

  // Check if user is signed in or not when load the app.
  useEffect(() => {
    const authenticate = async () => {
      try {
        const amplifyAuth = await import('aws-amplify/auth')
        const fetchAuthSession = amplifyAuth.fetchAuthSession
        const fetchUserAttributes = amplifyAuth.fetchUserAttributes
        const result = await fetchAuthSession({ forceRefresh: true })
        const userAttributes = await fetchUserAttributes()
        // To check if user is signed in or not
        // https://docs.amplify.aws/javascript/build-a-backend/auth/manage-user-session/#retrieve-a-user-session
        if (result.tokens && userAttributes.email) {
          setLoginStatus('loggedIn')
        } else {
          console.warn('user is not logged in')
          setLoginStatus('loggedOut')
        }
      } catch (err) {
        if (isMounted) {
          setLoginStatus('loggedOut')
        }
      }
    }
    authenticate()
  }, [isMounted])

  // listens to the global state
  useEffect(() => {
    const listenToGlobalEvents = async () => {
      const Hub = await import('aws-amplify/utils').then(mod => mod.Hub)
      Hub.listen('auth', data => {
        const { payload } = data
        // console.log('A new auth event has happened: ', data)
        if (payload.event === 'signedIn') {
          if (isMounted) {
            // console.log("a user has signed in!");
            setLoginStatus('loggedIn')
          }
        }
        if (payload.event === 'signedOut') {
          if (isMounted) {
            // console.log("a user has signed out!");
            setLoginStatus('loggedOut')
          }
        }
      })
    }
    listenToGlobalEvents()
  }, [isMounted])

  return <loginStatusContext.Provider value={loginStatus}>{children}</loginStatusContext.Provider>
}
