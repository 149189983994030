import { css, cx } from 'styled-system/css'
import { type CustomGridVariantProps } from 'styled-system/recipes'
import type { SystemStyleObject } from 'styled-system/types'

type GridProps = CustomGridVariantProps & {
  children?: React.ReactNode
  css?: SystemStyleObject
  className?: string
}
export const DotElastic = (props: GridProps) => {
  const { children, className, css: cssProp = {}, ...rest } = props
  const rootClassName = cx(css(dotElasticStyle, cssProp), className)
  return (
    <div {...rest} className={rootClassName}>
      {children}
    </div>
  )
}

const dotElasticStyle = css.raw({
  position: 'relative',
  width: '[12px]',
  height: '[12px]',
  borderRadius: '$round',
  backgroundColor: '$gs10',
  color: '$gs10',
  animationName: 'dotElastic',
  animationDuration: '[1s]',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'linear',
  _before: {
    content: '""',
    display: 'inline-block',
    position: 'absolute',
    top: '0',
    left: '[-17px]',
    width: '[12px]',
    height: '[12px]',
    borderRadius: '$round',
    backgroundColor: '$gs10',
    color: '$gs10',
    animationName: 'dotElasticBefore',
    animationDuration: '[1s]',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
  _after: {
    content: '""',
    display: 'inline-block',
    position: 'absolute',
    top: '0',
    left: '[17px]',
    width: '[12px]',
    height: '[12px]',
    borderRadius: '$round',
    backgroundColor: '$gs10',
    color: '$gs10',
    animationName: 'dotElasticAfter',
    animationDuration: '[1s]',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
})

// https://codepen.io/nzbin/pen/GGrXbp
