import { Pencil1Icon } from '@radix-ui/react-icons'
import React, { useCallback, useRef } from 'react'
import { css, cva, cx } from 'styled-system/css'
import type { SystemStyleObject } from 'styled-system/types'

import { debounce } from '../utils/other-utils'

type ButtonTypes = 'primary' | 'secondary' | 'tertiary' | 'ghost'

export interface IconButtonProps {
  /**
   *  Callback function triggered when btn clicked.
   */
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void

  /**
   *  Set classname of icon button if needed.
   */
  className?: string

  /**
   *  Expand icon button when hover with text.
   */
  expandable?: boolean

  /**
   *  Css property.
   */
  css?: SystemStyleObject

  /**
   *  Set the icon name.
   */
  reactIcon: React.ReactNode

  /**
   *  Set the color of icon button.
   */
  variant?: ButtonTypes

  /**
   *  Set the state of button.
   */
  state?: 'active' | 'disabled'

  /**
   *  Set the size of button.
   */
  size?: 'large' | 'small' | 'medium'

  /**
   * Button text on icon button.
   */
  text?: string

  children?: React.ReactNode
}

export const IconButton = React.forwardRef<HTMLDivElement, IconButtonProps>(
  (
    {
      className,
      reactIcon = <Pencil1Icon />,
      onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {},
      variant = 'primary',
      css: cssProp = {},
      state = 'active',
      size = 'small',
      text = '',
      expandable = false,
    },
    forwardRef,
  ) => {
    const ref = useRef<HTMLDivElement>(null)

    const onClickCallback = useCallback(
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        onClick(e)
      },
      [onClick],
    )

    const rippleContainerRef = useRef<HTMLDivElement>(null)
    /**
     * Adds ripple effect.
     * documentaiton: https://stackoverflow.com/questions/5999998/how-can-i-check-if-a-javascript-variable-is-function-type
     */
    const onAddRipple = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (ref && ref.current) {
        // following doc: https://medium.com/@jh3y/how-to-create-the-ripple-effect-from-google-material-design-c6f993e1d39
        const ripple = ref.current
        const rippleContainer = rippleContainerRef.current
        if (ripple && rippleContainer) {
          const size = ripple.offsetWidth
          const pos = ripple.getBoundingClientRect()
          const rippler = document.createElement('span')
          const x = e.pageX - pos.left - size / 2
          const y = e.pageY - pos.top - size / 2
          const style = `top: ${y}px; left: ${x}px; height: ${size}px; width: ${size}px;`
          rippleContainer.appendChild(rippler)
          rippler.setAttribute('style', style)
        }
      }
    }, [])

    const cleanUp = useCallback(() => {
      const container = rippleContainerRef.current
      if (container && rippleContainerRef && rippleContainerRef.current && rippleContainerRef.current.firstChild) {
        while (rippleContainerRef.current.firstChild && container.firstChild) {
          container.removeChild(container.firstChild)
        }
      }
    }, [])

    const rootClassName = cx(
      styledIconButton({
        variant,
        state,
        size,
        expandable,
      }),
      css(cssProp),
      className,
    )

    return (
      <div
        className={rootClassName}
        onMouseDown={onAddRipple}
        onMouseUp={debounce(cleanUp, 2000)}
        onClick={onClickCallback}
        ref={forwardRef}>
        <span className="btn-text">{text}</span>
        <span>{reactIcon}</span>
        <div className="ripple--container" ref={rippleContainerRef} />
      </div>
    )
  },
)

IconButton.displayName = 'IconButton'

const styledIconButton = cva({
  base: {
    // Reset
    all: 'unset',
    alignItems: 'center',
    appearance: 'none',
    borderWidth: '$0',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    flexShrink: 0,
    cursor: 'pointer',
    fontFamily: '$button',
    fontWeight: '$button',
    fontStyle: '[{fonts.$button_style}]',
    textTransform: '{fonts.$button_case}',
    lineHeight: '$button',
    fontSize: '$button',
    outline: 'none',
    overflow: 'hidden',
    textDecoration: 'none',
    userSelect: 'none',
    WebkitTapHighlightColor: 'transparent',
    position: 'relative',

    _before: {
      boxSizing: 'border-box',
    },
    _after: {
      // https://loading.io/button/
      zIndex: '$hidden',
      content: '" "',
      position: 'absolute',
      top: '[50%]',
      left: '[50%]',
      width: '[1em]',
      height: '[1em]',
      margin: '[-0.5em]',
      animationName: 'btnLoading',
      animationDuration: '[1s]',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear',
      backgroundColor: '$gs1',
      display: 'inline-block',
      boxSizing: 'content-box',
      transformOrigin: '50%',
      transformBox: 'fill-box',
      borderRadius: '$round',
      transitionProperty: 'all',
      transitionDuration: '$normal',
      transitionTimingFunction: 'in-out',
      opacity: 0,
    },
    '& .ripple--container': {
      position: 'absolute',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      transform: 'translateY(0)',
    },
    '& .ripple--container span': {
      transform: 'scale(0)',
      borderRadius: '$round',
      position: 'absolute',
      opacity: '0.75',
      backgroundColor: '$gs1',
      animationName: 'btnRipple',
      animationDuration: '[1000ms]',
    },

    '& .btn-text': {
      mr: '$4',
      display: 'none',
    },
  },

  variants: {
    variant: {
      primary: {
        color: '$btnPriText',
        backgroundColor: '$btnPriBg',
        _hover: {
          backgroundColor: '$btnPriBg_L',
        },
        '&:active': {
          backgroundColor: '$btnPriBg_D',
        },
      },
      secondary: {
        color: '$btnSecText',
        backgroundColor: '$btnSecBg',
        _hover: {
          backgroundColor: '$btnSecBg_L',
        },
        '&:active': {
          backgroundColor: '$btnSecBg_D',
        },
      },
      tertiary: {
        color: '$gs12',
        backgroundColor: '$btnTerBg',
        _hover: {
          backgroundColor: '$gs4',
        },
        '&:active': {
          backgroundColor: '$gs5',
        },
      },
      ghost: {
        color: '$btnGhoText',
        backgroundColor: '$none',
        _hover: {
          backgroundColor: '$gs4',
        },
        '&:active': {
          backgroundColor: '$gs5',
        },
      },
    },
    state: {
      active: {},
      waiting: {
        backgroundColor: '$gs5',
        boxShadow: '[inset 0 0 0 1px {colors.$gs6}]',
        _hover: {
          boxShadow: '[inset 0 0 0 1px {colors.$gs6}]',
        },
        '&:active': {
          backgroundColor: '$gs5',
        },
      },
      disabled: {},
    },
    size: {
      small: {
        width: '$10',
        height: '$10',

        '& .material-icons-outlined': {
          fontSize: '[19px]',
        },
      },
      medium: {
        width: '$12',
        height: '$12',

        '& .material-icons-outlined': {
          fontSize: '[19px]',
        },
      },
      large: {
        width: '[70px]',
        height: '[70px]',
        '& .material-icons-outlined': {
          fontSize: '[24px]',
        },
      },
    },
    expandable: {
      true: {
        width: '[max-content]',
        _hover: {
          borderRadius: '$0',
          '.btn-text': {
            display: 'block',
          },
        },
      },
      false: {
        '.btn-text': {
          display: 'none',
        },
      },
    },
  },
  compoundVariants: [
    {
      state: 'disabled',
      variant: 'primary',
      css: {
        backgroundColor: '$gs8',
        color: '$gs9',
        cursor: 'not-allowed',
        _hover: {
          backgroundColor: '$gs8',
          color: '$gs9',
        },
      },
    },
    {
      state: 'disabled',
      variant: 'secondary',
      css: {
        backgroundColor: '$none',
        color: '$gs8',
        cursor: 'not-allowed',
        borderStyle: '$btnSec',
        borderWidth: '$btnTer',
        borderColor: '$gs8',
        _hover: {
          backgroundColor: '$none',
          color: '$gs8',
        },
      },
    },
    {
      state: 'disabled',
      variant: 'ghost',
      css: {
        backgroundColor: '$none',
        color: '$gs8',
        cursor: 'not-allowed',
        _hover: {
          backgroundColor: '$none',
          color: '$gs8',
        },
      },
    },
    {
      state: 'disabled',
      variant: 'tertiary',
      css: {
        backgroundColor: '$none',
        color: '$gs8',
        borderWidth: '$btnTer',
        borderStyle: '$btnTer',
        borderColor: '$gs8',
        cursor: 'not-allowed',
        _hover: {
          backgroundColor: '$none',
          color: '$gs8',
          borderWidth: '$btnTer',
          borderStyle: '$btnTer',
          borderColor: '$gs8',
        },
      },
    },
    {
      size: 'small',
      expandable: true,
      css: {
        width: '[max-content]',
        minWidth: '$10',
        _hover: {
          px: '$4',
        },
      },
    },
    {
      size: 'medium',
      expandable: true,
      css: {
        width: '[max-content]',
        minWidth: '$12',
        _hover: {
          px: '$4',
        },
      },
    },
    {
      size: 'large',
      expandable: true,
      css: {
        width: '[max-content]',
        minWidth: '[70px]',
        _hover: {
          px: '$4',
        },
      },
    },
  ],
  defaultVariants: {
    variant: 'primary',
    state: 'active',
    size: 'small',
    expandable: false,
  },
})
