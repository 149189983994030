import { forwardRef } from 'react'
import { css, cx } from 'styled-system/css'
import type { SystemStyleObject } from 'styled-system/types'

const boxStyles = css.raw({
  boxSizing: 'border-box',
})

type BoxProps = {
  children?: React.ReactNode
  css?: SystemStyleObject
  className?: string
  onClick?: (e) => void
  id?: string
}

export const Box = forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  const { children, css: cssProp = {}, className, onClick, ...rest } = props
  return (
    <div {...rest} className={cx(css(boxStyles, cssProp), className)} onClick={onClick} ref={ref}>
      {children}
    </div>
  )
})
Box.displayName = 'Box'
