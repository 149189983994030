'use client'

import { ExclamationTriangleIcon, EyeNoneIcon, EyeOpenIcon } from '@radix-ui/react-icons'
import React, { useCallback, useEffect, useState } from 'react'
import { css, cva, cx } from 'styled-system/css'
import type { SystemStyleObject } from 'styled-system/types'

import { TypographyVariants } from '../utils/typescript-utils'
import { Box } from './Box'
import { CustomFlex } from './CustomFlex'
import { Icon } from './Icon'
import { Text } from './Text'

const DISABLED_DEFAULT = false

export type TextFieldProps = {
  /**
   *  Check to align text in center.
   */
  centerText?: boolean

  characterCountIsVisible?: boolean

  characterCountLimit?: number

  /**
   *  Check to auto complete the text field.
   */
  autocomplete?: 'off' | 'on'

  /**
   *  Check to auto grow the text field vertically.
   */
  autoGrow?: boolean

  /**
   *  Give css property to element.
   */
  css?: SystemStyleObject

  /**
   *  Give caption to textfield.
   */
  caption?: string

  /**
   * Full width of its parent element.
   */
  fullWidth?: boolean

  /**
   *  Set to true to disable component.
   * Defaults to false
   */
  disabled?: boolean

  /**
   *  Set error text of textfield.
   */
  errorMsg?: string

  /**
   *  Set warning text of textfield.
   */
  warningMsg?: string

  /**
   *  Set the label of text field.
   */
  floatingLabel?: string

  /**
   *  Use react icon inside text field.
   */
  leftIcon?: React.ReactNode

  /**
   * Right react to check the input field.
   */
  rightIcon?: React.ReactNode

  /**
   * Name of the textfield.
   */
  name: string

  /**
   *  Callback function to interact with textfield.
   */
  onChange?: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void

  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) => void

  /**
   * On Blur callbak.
   */
  onBlur?: (e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) => void
  /**
   *  On Focus callback.
   */
  onFocus?: (e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) => void

  /**
   *  Set the placeholder text.
   */
  placeholder?: string

  /**
   *  Set the number of rows in textarea.
   */
  rows?: number

  /**
   *  Get the value of text field.
   */
  value?: string | number | null

  /**
   *  Check whether to use as password or usual input.
   */
  type?: string

  /**
   *  Set the font size of inputting text.
   */
  inputVariant?: TypographyVariants

  /**
   *  Set the font size of caption.
   */
  captionVariant?: TypographyVariants

  /**
   *  Set font size of error message.
   */
  errorMsgVariant?: TypographyVariants

  /**
   *  Set font size of floating label.
   */
  floatingLabelVariant?: TypographyVariants

  /**
   * Set autofocus on textfield.
   * Defaults to false
   */
  autoFocus?: boolean

  /**
   * Set read only on textfield.
   */
  readOnly?: boolean

  inputExtraOptions?: React.InputHTMLAttributes<HTMLInputElement>

  reactFormErrors?: unknown // TODO: replace any by something with FieldErrors like FieldErrors<Record<string, any>>
  className?: string

  maxDate?: string
}

export const Textfield = React.forwardRef<HTMLDivElement, TextFieldProps>((props, forwardRef) => {
  const {
    autocomplete,
    disabled = DISABLED_DEFAULT,
    placeholder,
    value = '',
    type = 'text',
    css: cssProp = {},
    centerText = false,
    fullWidth = FULL_WIDTH_DEFAULT,
    leftIcon,
    rightIcon,
    onChange,
    onBlur,
    onFocus,
    caption,
    errorMsg,
    warningMsg,
    floatingLabel,
    name,
    rows,
    inputVariant = 'body1',
    captionVariant,
    floatingLabelVariant,
    errorMsgVariant,
    autoFocus = false,
    readOnly,
    inputExtraOptions,
    reactFormErrors,
    onKeyDown,
    autoGrow = false,
    characterCountIsVisible = false,
    characterCountLimit,
    className,
    maxDate,
  } = props
  const [isInputFocused, setIsInputFocused] = useState(autoFocus)
  const [revealPassword, setRevealPassword] = useState(false)
  const textfieldValue = value || ''
  const textareaRef = React.useRef<HTMLTextAreaElement>(null)

  const handleAutoGrow = useCallback(
    e => {
      if (autoGrow) {
        e.currentTarget.style.height = 'inherit'
        e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`
      }
    },
    [autoGrow],
  )

  useEffect(() => {
    if (autoGrow && textareaRef && textareaRef.current) {
      textareaRef.current.style.height = 'inherit'
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }, [autoGrow])
  return (
    <CustomFlex
      direction="column"
      className={cx(textfieldWrapper({ isFullWidth: fullWidth }), className)}
      css={cssProp}
      ref={forwardRef}>
      {(floatingLabel || characterCountIsVisible) && (
        <CustomFlex gap={'2'}>
          {floatingLabel && (
            <Text
              className={floatingLabelText({ disabled })}
              variant={floatingLabelVariant ? floatingLabelVariant : 'overline'}>
              {floatingLabel}
            </Text>
          )}
          {characterCountIsVisible && (
            <Text
              className={characterCountText({
                isOverLimit: characterCountLimit ? textfieldValue.toString()?.length > characterCountLimit : false,
              })}
              variant={floatingLabelVariant ? floatingLabelVariant : 'overline'}>
              {textfieldValue.toString().length}/{characterCountLimit}
            </Text>
          )}
        </CustomFlex>
      )}
      {rows ? (
        <CustomFlex css={css.raw({ borderWidth: '$1', borderStyle: 'solid', borderColor: '$gs6', borderRadius: '$3' })}>
          <textarea
            ref={textareaRef}
            onChange={onChange}
            placeholder={placeholder}
            name={name}
            rows={rows}
            value={textfieldValue}
            onBlur={onBlur}
            autoComplete={autocomplete}
            readOnly={readOnly}
            disabled={disabled}
            onFocus={onFocus}
            onKeyUp={handleAutoGrow}
            onKeyDown={onKeyDown}
            className={cx(textArea({ disabled }), css({ txtStyle: inputVariant }))}
          />
        </CustomFlex>
      ) : (
        <CustomFlex
          className={singleLineTextfieldContainer({
            centerText,
            bottomRadius: errorMsg || warningMsg ? 'straight' : 'curved',
            borderColor: isInputFocused ? 'pronounced' : 'slight',
          })}
          align="center">
          {leftIcon && (
            <Box css={css.raw({ cursor: 'pointer', pl: '$4' })}>
              <Icon reactIcon={leftIcon} css={css.raw({ color: disabled ? '$gs8' : '$gs11' })} />
            </Box>
          )}
          <input
            className={cx(input({ disabled }), css({ txtStyle: inputVariant }))}
            value={textfieldValue}
            type={type !== 'password' ? type : revealPassword ? 'text' : 'password'}
            placeholder={placeholder}
            onChange={onChange}
            name={name}
            onBlur={e => {
              setIsInputFocused(false)
              if (onBlur) {
                onBlur(e)
              }
            }}
            onFocus={e => {
              setIsInputFocused(true)
              if (onFocus) onFocus(e)
            }}
            onKeyDown={onKeyDown}
            autoComplete={autocomplete}
            disabled={disabled}
            autoFocus={autoFocus}
            readOnly={readOnly}
            max={maxDate}
            {...inputExtraOptions}
          />
          {type === 'password' && (
            <Box css={css.raw({ mr: '$4', cursor: 'pointer' })} onClick={() => setRevealPassword(reveal => !reveal)}>
              <Icon
                className={styledIcon({ isDisabled: disabled, isError: !!errorMsg, isWarning: !!warningMsg })}
                reactIcon={revealPassword ? <EyeOpenIcon /> : <EyeNoneIcon />}
                pointer={true}
              />
            </Box>
          )}
          {(rightIcon || !!errorMsg || !!warningMsg) && type === 'password' && (
            <Box css={css.raw({ mr: '$4', cursor: 'pointer' })}>
              <Icon
                className={styledIcon({ isDisabled: disabled, isError: !!errorMsg, isWarning: !!warningMsg })}
                reactIcon={errorMsg || warningMsg ? <ExclamationTriangleIcon /> : rightIcon}
              />
            </Box>
          )}
        </CustomFlex>
      )}
      {(errorMsg || (reactFormErrors && reactFormErrors[name]?.message)) && (
        <CustomFlex className={msgWrapper({ status: 'error' })} align="center">
          <Text
            className={errorText({ centerText, type: 'error' })}
            variant={errorMsgVariant ? errorMsgVariant : 'caption'}>
            {errorMsg || (reactFormErrors ? reactFormErrors[name].message : '')}
          </Text>
        </CustomFlex>
      )}
      {warningMsg && (
        <CustomFlex className={msgWrapper({ status: 'warning' })} align="center">
          <Text variant="caption" className={errorText({ centerText, type: 'warning' })}>
            {warningMsg}
          </Text>
        </CustomFlex>
      )}
      {caption && !errorMsg && (
        <Text variant={captionVariant ? captionVariant : 'caption'} className={captionText({ disabled })}>
          {caption}
        </Text>
      )}
    </CustomFlex>
  )
})

Textfield.displayName = 'Textfield'

const errorText = cva({
  base: {
    wordBreak: 'break-all',
  },
  variants: {
    type: {
      warning: {
        color: '$warText',
      },
      error: {
        color: '$aleText',
      },
    },
    centerText: {
      true: {
        width: '[100%]',
        textAlign: '[center]',
      },
      false: {},
    },
  },
  defaultVariants: {
    centerText: false,
  },
})

const styledIcon = cva({
  base: { color: '$gs1' },
  variants: {
    isDisabled: {
      true: {
        color: '$gs8',
      },
      false: {
        color: '$gs11',
      },
    },
    isError: { true: {}, false: {} },
    isWarning: { true: {}, false: {} },
  },
  compoundVariants: [
    {
      isError: true,
      isDisabled: false,
      css: {
        color: '$ale',
      },
    },
    {
      isWarning: true,
      isDisabled: false,
      css: {
        color: '$war',
      },
    },
  ],
  defaultVariants: {
    isDisabled: DISABLED_DEFAULT,
    isError: false,
    isWarning: false,
  },
})

const input = cva({
  base: {
    all: 'unset',
    width: '$full',
    height: '$full',
    px: '$4',
    py: '$3',
    boxSizing: 'border-box',
    color: '$gs12',
    _placeholder: {
      color: '$gs11',
    },
    _focus: {
      border: 'none',
    },
  },
  variants: {
    disabled: {
      true: {
        color: '$gs8',
        pointerEvents: 'none',
        _placeholder: {
          color: '$gs8',
        },
      },
      false: {},
    },
  },
  defaultVariants: {
    disabled: false,
  },
})

const singleLineTextfieldContainer = cva({
  base: {
    height: '$12',
    borderTopLeftRadius: '$3',
    borderTopRightRadius: '$3',
    backgroundColor: '$gs3',
  },
  variants: {
    centerText: {
      true: {
        textAlign: '[center]',
      },
      false: {},
    },
    bottomRadius: {
      curved: {
        borderBottomLeftRadius: '$3',
        borderBottomRightRadius: '$3',
      },
      straight: {
        borderBottomLeftRadius: '$0',
        borderBottomRightRadius: '$0',
      },
    },
    borderColor: {
      pronounced: {
        borderBottomWidth: '$1', // TODO: see if only border-bottom color is enough
        borderBottomStyle: '[solid]',
        borderBottomColor: '$gs6',
      },
      slight: {
        borderBottomWidth: '$1',
        borderBottomStyle: '[solid]',
        borderBottomColor: '$gs4',
      },
    },
  },
  defaultVariants: {
    bottomRadius: 'curved',
    borderColor: 'pronounced',
  },
})

const textArea = cva({
  base: {
    all: 'unset',
    width: '$full',
    borderRadius: '$3',
    wordBreak: 'break-word',
    px: '$4',
    py: '$2',
    boxSizing: 'border-box',
    color: '$gs12',
    backgroundColor: '$gs3',
    resize: 'vertical',
    _placeholder: {
      color: '$gs11',
    },
    _focus: {
      border: 'none',
    },
  },
  variants: {
    disabled: {
      true: {
        color: '$gs8',
        pointerEvents: 'none',
        _placeholder: {
          color: '$gs8',
        },
      },
      false: {},
    },
  },
  defaultVariants: {
    disabled: false,
  },
})

const FULL_WIDTH_DEFAULT = false

const textfieldWrapper = cva({
  variants: {
    isFullWidth: {
      true: {
        width: '$full',
      },
      false: {
        width: '[355px]',
      },
    },
  },
  defaultVariants: {
    isFullWidth: FULL_WIDTH_DEFAULT,
  },
})

const floatingLabelText = cva({
  base: { pb: '$1' },
  variants: {
    disabled: {
      true: {
        color: '$gs8',
      },
      false: {
        color: '$gs11',
      },
    },
  },
  defaultVariants: {
    disabled: DISABLED_DEFAULT,
  },
})

const characterCountText = cva({
  variants: {
    isOverLimit: {
      true: {
        color: '$ale',
      },
      false: {
        color: '$gs11',
      },
    },
  },
  defaultVariants: {
    isOverLimit: false,
  },
})

const captionText = cva({
  base: {
    mt: '$2',
    wordBreak: 'break-all',
  },
  variants: {
    disabled: {
      true: {
        color: '$gs8',
      },
      false: {
        color: '$gs11',
      },
    },
  },
  defaultVariants: {
    disabled: DISABLED_DEFAULT,
  },
})

const msgWrapper = cva({
  base: {
    px: '$4',
    py: '$2',
    borderBottomLeftRadius: '$3',
    borderBottomRightRadius: '$3',
  },
  variants: {
    status: {
      error: {
        backgroundColor: '$ale',
      },
      warning: {
        backgroundColor: '$war',
      },
    },
  },
  defaultVariants: {
    status: 'error',
  },
})
