'use client'

import { Cross1Icon } from '@radix-ui/react-icons'
import React from 'react'
import { css, cva, cx } from 'styled-system/css'
import type { SystemStyleObject } from 'styled-system/types'

import { TypographyVariants } from '../utils/typescript-utils'
import { Avatar, AvatarProps } from './Avatar'
import { Box } from './Box'
import { IconButton } from './IconButton'
import { Text } from './Text'

export interface TagType {
  /**
   * Id of the tag
   */
  id: string

  /**
   * Material icon name. Will display on the right of the tag.
   */
  reactIcon?: React.ReactNode

  /**
   * Determines a count number in the context of the tag.
   * Will appear on the right hand side of the tag name in ().
   */
  count?: number

  /**
   * Displays as image on the left hand side of the tag title.
   */
  imgSrc?: string

  imgWidth?: number

  imgHeight?: number

  imgType?: AvatarProps['imgType']

  /**
   * Specified is tag is already selected. Defaults to false
   */
  isSelected?: boolean

  /**
   * The orientation of the scrollbar
   */
  label?: string

  /**
   * Content for button trigger.
   */
  variant?: TypographyVariants

  /**
   * Tags types
   */
  type?: 'default' | 'delete' | 'select'

  /**
   *  Give class name to icon button.
   */
  className?: string

  /**
   *  Set the css property.
   */
  css?: SystemStyleObject

  /**
   * Callback function when tag is clicked
   */
  onClick?: (id: string) => void

  /**
   * Callback function when delete is clicked
   */
  onDelete?: (id: string) => void

  /**
   * Tag Size
   */
  size?: 'small' | 'large'

  /**
   * Tag is selectable
   */
  selectable?: boolean

  capitalize?: boolean
}

export const Tag = React.forwardRef<HTMLDivElement, TagType>(
  (
    {
      id,
      label,
      isSelected,
      selectable = true,
      reactIcon,
      imgSrc,
      imgHeight,
      imgWidth,
      imgType,
      variant = 'body2',
      className,
      css: cssProp = {},
      onClick = (id: string) => {},
      onDelete = (id: string) => {},
      count,
      type = 'default',
      size = 'small',
      capitalize = true,

      ...rest
    },
    forwardRef,
  ) => {
    const rootClassName = cx(
      styledTag({
        size,
        selectable,
        active: isSelected,
      }),
      css(cssProp),
      className,
    )

    return (
      <Text
        // ref={forwardRef} // TODO: Commented in Panda. See if I can remove it.
        className={rootClassName}
        variant={variant}
        onClick={() => onClick(id)}
        {...rest}>
        {/* add image to Avatar  */}
        {!!(imgSrc && imgWidth && imgHeight) && (
          <Avatar
            imgSrc={imgSrc}
            imgHeight={imgHeight}
            imgWidth={imgWidth}
            imgType={imgType}
            css={css.raw({
              padding: '$0',
              margin: '[2px 0]',
              width: '[25px !important]',
              minWidth: '[25px !important]',
              height: '[25px !important]',
              '& img': {
                width: '[25px !important]',
                height: '[25px !important]',
              },
            })}
          />
        )}
        <Box css={css.raw({ textTransform: capitalize ? 'capitalize' : 'none' })}>{label}</Box>
        {reactIcon && !imgSrc && (
          <IconButton
            variant="ghost"
            reactIcon={reactIcon}
            css={css.raw({
              width: 'auto',
              height: 'auto',
              color: '$gs12',
            })}
          />
        )}
        {type === 'delete' && (
          <IconButton
            variant="ghost"
            reactIcon={<Cross1Icon />}
            css={css.raw({
              width: 'auto',
              height: 'auto',
              ml: '$3',
              color: '$gs12',
            })}
            onClick={() => onDelete(id)}
          />
        )}
        {count && count > 0 ? ` (${count})` : ''}
        {type === 'select' && <Box css={optionSelector}>{isSelected && <Box css={optionSelectorContent} />}</Box>}
      </Text>
    )
  },
)
Tag.displayName = 'Tag'

const styledTag = cva({
  base: {
    color: '$gs12',
    backgroundColor: '$gs3',
    display: 'flex !important',
    gap: '$1',
    alignItems: 'center',
    cursor: 'pointer',
    width: 'fit',
    borderRadius: '$3',
    boxSizing: 'content-box',
  },

  variants: {
    size: {
      small: {
        paddingLeft: '$2',
        paddingRight: '$1',
      },
      large: {
        paddingLeft: '$4',
        paddingRight: '$3',
        py: '$2',
      },
    },
    selectable: {
      true: {
        opacity: '1',
        cursor: 'pointer',
      },
      false: {
        opacity: '0.5',
        cursor: 'not-allowed',
      },
    },
    active: {
      true: {
        borderWidth: '$1',
        borderStyle: 'solid',
        borderColor: '$sec',
        background: '$gs6',
      },
      false: {
        borderWidth: '$1',
        borderStyle: 'solid',
        borderColor: '$gs4',
      },
    },
  },
  compoundVariants: [
    {
      selectable: true,
      active: true,
      css: {
        _hover: {
          borderWidth: '$1',
          borderColor: '$sec',
          borderStyle: 'solid',
        },
      },
    },
    {
      selectable: true,
      active: false,
      css: {
        _hover: {
          backgroundColor: '$gs4',
          color: '$gs11',
          '& > *': {
            color: '$gs11',
            borderColor: '$gs11',
          },
        },
      },
    },
  ],
  defaultVariants: {
    active: false,
    size: 'small',
    selectable: true,
  },
})

const optionSelector = css.raw({
  width: '$3',
  height: '$3',
  borderWidth: '[1.5px]',
  borderColor: '$gs12',
  borderStyle: 'solid',
  borderRadius: '$round',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ml: '$3',
})

const optionSelectorContent = css.raw({
  width: '[6px]',
  height: '[6px]',
  background: '$gs12',
  borderRadius: '$round',
})
