'use client'

import * as RadixTooltip from '@radix-ui/react-tooltip'
import { ReactNode } from 'react'
import { css } from 'styled-system/css'
import type { SystemStyleObject } from 'styled-system/types'

export interface ToolTipProps {
  /**
   * Triggered Component
   */
  children?: ReactNode

  /**
   * ToolTip content
   */
  content?: ReactNode

  /**
   * Tooltip width
   */
  width?: string

  maxWidth?: string

  /**
   * CSS
   */
  css?: SystemStyleObject

  isOpen?: boolean

  // Defaults to false
  useDivElementForTrigger?: boolean
  className?: string
  ariaLabel?: string
}

export const Tooltip: React.FC<ToolTipProps> = ({
  children,
  content = '',
  css: cssProp = {},
  maxWidth = '500px',
  isOpen,
  useDivElementForTrigger = false,
  ariaLabel,
}) => {
  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root delayDuration={700} open={isOpen}>
        <RadixTooltip.Trigger
          className={css(tooltipTrigger, cssProp)}
          asChild={useDivElementForTrigger}
          aria-label={ariaLabel}>
          {useDivElementForTrigger ? <div>{children}</div> : children}
        </RadixTooltip.Trigger>
        <RadixTooltip.Content style={{ maxWidth }} className={css(tooltipContent)}>
          {content}
          <RadixTooltip.Arrow height={6} width={12} offset={10} className={toolTipArrow} />
        </RadixTooltip.Content>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  )
}

const tooltipContent = {
  txtStyle: 'caption',
  transformOrigin: 'var(--radix-tooltip-content-transform-origin)',
  background: '$gs3',
  color: '$gs12',
  p: '$3',
  // borderBottom: 'solid 1px',
  // borderColor: '$sec',
  borderRadius: '$3',
  // Box shadow on bottom only
  // boxShadow: '0 4px 4px $colors$sec',
  marginTop: '$1',
  position: 'relative',
  width: 'fit',
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '[400ms]',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    willChange: 'transform, opacity',
    '&[data-state="delayed-open"]': {
      '&[data-side="top"]': { animationName: 'tooltipSlideDownAndFade' },
      '&[data-side="right"]': { animationName: 'tooltipSlideLeftAndFade' },
      '&[data-side="bottom"]': { animationName: 'tooltipSlideUpAndFade' },
      '&[data-side="left"]': { animationName: 'tooltipSlideRightAndFade' },
    },
  },
} as const

const toolTipArrow = css({ fill: '$gs3' })

const tooltipTrigger = css.raw({
  background: '[none]',
  outline: 'none',
  padding: '$0',
  border: 'none',
})
