import { PersonIcon } from '@radix-ui/react-icons'
import React from 'react'
import { css, cva, cx } from 'styled-system/css'
import type { SystemStyleObject } from 'styled-system/types'

import { Icon } from './Icon'
import { ShoImage } from './ShoImage'
import { Text } from './Text'

export interface AvatarProps {
  /**
   * Give css property to accordion component.
   */
  css?: SystemStyleObject

  customReactIcon?: React.ReactNode

  /**
   * Initials of the user's first name.
   */
  firstName?: string

  /**
   * img
   * */
  imgSrc?: string

  imgWidth?: number

  imgHeight?: number

  imgType?: 'circle' | 'square'

  /**
   * Initials of the user's last name.
   */
  lastName?: string
  className?: string
}

// TODO: add option for image
export const Avatar: React.FC<AvatarProps> = ({
  css: cssProp = {},
  firstName,
  lastName,
  customReactIcon,
  imgSrc,
  imgHeight,
  imgWidth,
  imgType = 'circle',
  className,
}) => {
  const _firstNameInitial = firstName ? firstName.slice(0, 1).toUpperCase() : ''
  const _lastNameInitial = lastName ? lastName.slice(0, 1).toUpperCase() : ''
  const hasInitials = _firstNameInitial || _lastNameInitial
  const type = imgSrc && imgHeight && imgWidth ? 'image' : hasInitials ? 'initials' : 'icon'

  const rootClassName = cx(
    container({
      radius: imgType,
    }),
    css(cssProp),
    className,
  )

  return (
    <Text className={rootClassName}>
      {type === 'image' && (
        <ShoImage
          src={imgSrc || ''}
          width={imgWidth}
          height={imgHeight}
          alt={`${firstName} ${lastName}`}
          sizes="32px"
          imageCss={css.raw({
            width: '[32px]',
            height: '[32px]',
            borderRadius: imgType === 'circle' ? '[50%]' : '$0',
            objectFit: 'cover',
          })}
        />
      )}
      {type === 'initials' && (
        <span className={initialsContainer}>
          {_firstNameInitial}
          {_lastNameInitial}
        </span>
      )}
      {type === 'icon' && <Icon reactIcon={customReactIcon ? customReactIcon : <PersonIcon />} />}
    </Text>
  )
}

const container = cva({
  base: {
    backgroundColor: '$b5',
    color: '$gs8',
    borderRadius: '[50%]',
    width: '[32px]',
    minWidth: '[32px]',
    height: '[32px]',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '[32px !important]',
  },
  variants: {
    radius: {
      circle: {
        borderRadius: '[50%]',
      },
      square: {
        borderRadius: '$0',
      },
    },
  },
  defaultVariants: {
    radius: 'circle',
  },
})
const initialsContainer = css({
  fontSize: '[14px]',
  textTransform: 'capitalize',
})
