import { FC } from 'react'
import { css, cx } from 'styled-system/css'
import { type SkeletonVariantProps, skeleton } from 'styled-system/recipes'
import type { SystemStyleObject } from 'styled-system/types'

type SkeletonProps = SkeletonVariantProps & {
  css?: SystemStyleObject
  className?: string
  onMouseDown?: (event: React.MouseEvent<HTMLSpanElement>) => void
  onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void
  children?: React.ReactNode
}

export const Skeleton: FC<SkeletonProps> = props => {
  const { children, className, css: cssProp = {}, color, height, variant, verticalSpacing, width, ...rest } = props
  const rootClassName = cx(
    skeleton({
      color,
      height,
      variant,
      verticalSpacing,
      width,
    }),
    css(cssProp),
    className,
  )
  // SepearatorPrimitive is a client component from Radix. Need to make this file a client file as well
  return (
    <div {...rest} className={rootClassName}>
      {children}
    </div>
  )
}
