'use client'

import Image, { ImageLoader, ImageProps } from 'next/image'
import { forwardRef, useMemo } from 'react'
import { css, cx } from 'styled-system/css'
import type { SystemStyleObject } from 'styled-system/types'

export type ShoImageProps = ImageProps & {
  /**
   * base64 placeholder image that will be used as blury placeholder while image is loading
   */
  blurryPlaceholder?: string | null
  /**
   * Does not transform the URL if on sho-app.
   * Defaults to false
   */
  doNotTransformURL?: boolean
  /**
   * set css of parent element
   */
  fillWrapperCss?: SystemStyleObject
  /**
   * Explicits if the asset is public or private (AWS).
   * Defaults to false
   */
  isPrivate?: boolean
  /**
   * Set the custom css on the image
   * @default {}
   */
  imageCss?: SystemStyleObject
  /**
   * JWT token used for private assets.
   */
  jwt?: 'string'
}

export const ShoImage = forwardRef<HTMLImageElement, ShoImageProps>(
  (
    {
      imageCss = {},
      fillWrapperCss = {},
      src,
      doNotTransformURL = false,
      isPrivate = false,
      jwt,
      blurryPlaceholder,
      alt = 'image',
      className,
      ...rest
    },
    forwardedRef,
  ) => {
    const transformedSrc = useMemo(() => {
      if (
        !doNotTransformURL &&
        typeof src === 'string' &&
        process.env.NEXT_PUBLIC_PUBLIC_ASSET &&
        process.env.NEXT_PUBLIC_PRIVATE_ASSET &&
        !src.startsWith('/') &&
        !src.startsWith('http')
      ) {
        const baseUrl = isPrivate ? process.env.NEXT_PUBLIC_PRIVATE_ASSET : process.env.NEXT_PUBLIC_PUBLIC_ASSET
        return (src = `https://${baseUrl}/${src}${isPrivate && jwt ? `?jwt=${jwt}` : ''}`)
      }
      return src
    }, [src, doNotTransformURL, isPrivate, jwt])

    const loader = useMemo(() => (doNotTransformURL ? ({ src }) => src : myLoader), [doNotTransformURL])
    const placeholder = useMemo(
      () => (blurryPlaceholder ? 'blur' : 'empty') as ImageProps['placeholder'],
      [blurryPlaceholder],
    )
    const imgProps = {
      ...rest,
      loader,
      placeholder,
      blurDataURL: blurryPlaceholder || undefined,
      src: transformedSrc,
      alt,
    } satisfies ImageProps

    if (!imgProps.src) {
      console.error('ShoImage attempted to be used with missing src')
      return null
    }

    const containerClassname = cx(
      css(
        {
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        },
        fillWrapperCss,
      ),
      className,
    )

    return rest.fill ? (
      <div className={containerClassname} ref={forwardedRef}>
        <Image {...imgProps} className={css(imageCss)} alt={imgProps.alt || 'img'} />
      </div>
    ) : (
      <Image {...imgProps} className={cx(css(imageCss), className)} ref={forwardedRef} alt={imgProps.alt || 'img'} />
    )
  },
)
ShoImage.toString = () => `.sho-image`
ShoImage.displayName = 'ShoImage'

const myLoader: ImageLoader = ({ src, width, quality }) => {
  if (typeof src !== 'string') return ''
  if (src.includes('.ico')) return src
  const withAndQualityQueryParams = `w=${width}${quality ? `&q=${quality}` : ''}`
  if (src.includes('?')) {
    return `${src}&${withAndQualityQueryParams}`
  }
  return `${src}?${withAndQualityQueryParams}`
}
